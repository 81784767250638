<template>
  <page-header-wrapper >
    <span slot="content">
      用户ID： {{ this.queryParam.playerId }}
    </span>
    <a-card :bordered="false">

      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
      </div>

      <s-table
        ref="table"
        size="default"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data="loadData"
      >
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)">修改</a>
            <a-divider type="vertical" />
            <a-popconfirm
              title="你确定要删除吗?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleDelete(record)"
            >
              <a href="#">删除</a>
            </a-popconfirm>
          </template>
        </span>
      </s-table>
      <player-item-form
        ref="itemForm"
        :visible="formVisible"
        :loading="confirmLoading"
        :model="formModel"
        @cancel="handleCancel"
        @ok="handleSave"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable } from '@/components'
import {
  deletePlayerItem, getPlayerItemList, savePlayerItem
} from '@/api/game/mai2'
import PlayerItemForm from '@/views/game/mai2/module/PlayerItemForm'
import { Mai2ItemKind } from '@/model/mai2const'

export default {
  name: 'PlayerItemList',
  components: {
    PlayerItemForm,
    STable
  },
  data () {
    return {
      formVisible: false,
      confirmLoading: false,
      formModel: null,
      queryParam: {
        playerId: this.$route.params.playerId
      },
      loadData: parameter => {
        return getPlayerItemList(Object.assign(parameter, this.queryParam))
      },
      currentPass: 'LOADING',
      columns: [
        {
          title: '物品类型',
          dataIndex: 'itemKind',
          customRender: (record) => {
            return Mai2ItemKind[record].label
          },
          sorter: true
        },
        {
          title: '物品ID',
          dataIndex: 'itemId',
          sorter: true
        },
        {
          title: '数量',
          dataIndex: 'stock',
          sorter: true
        },
        {
          title: '是否可用',
          dataIndex: 'isValid',
          customRender: (isValid) => isValid ? '是' : '否',
          sorter: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  methods: {
    handleAdd () {
      this.$refs.itemForm.form.resetFields()
      this.formModel = null
      this.formVisible = true
    },
    handleCancel () {
      this.formVisible = false
    },
    handleEdit (record) {
      this.$refs.itemForm.form.resetFields()
      record.isValid = record.isValid ? 'true' : 'false'
      this.formModel = record
      this.formVisible = true
    },
    handleSave () {
      const form = this.$refs.itemForm.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          values.isValid = values.isValid === 'true'
          savePlayerItem(this.queryParam, values).then(() => {
            this.formVisible = false
            form.resetFields()
            this.$refs.table.refresh()
            this.$message.success('保存成功')
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleDelete (record) {
      deletePlayerItem({ playerId: this.queryParam.playerId, itemId: record.itemId, itemKind: record.itemKind }).then(() => {
        this.$message.success('删除成功')
        this.$refs.table.refresh()
      })
    }
  }
}
</script>

<style scoped>

</style>
